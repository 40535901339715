import React, { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Icon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const Liberacao = () => {
  let timer;
  const waitTime = 1000;
  const navigate = useNavigate();
  const nfRef = useRef(null);
  const defaultValues = { nf: '' };
  const [itens, setItens] = useState([]);
  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const { postLiberacao, postLoading } = useContext(ExpedicoesContext);

  const onRead = (data) => {
    const nfs = itens?.filter((f) => f?.nf != data?.nf);
    setItens([data, ...nfs]);
    reset();
  };

  const onSubmit = () => postLiberacao({ data: itens, cb: () => navigate(-1) });

  return (
    <Container>
      <Header titulo="Liberação de Carga" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              inputRef={nfRef}
              name="nf"
              control={control}
              label="Nota Fiscal"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    handleSubmit(onRead)();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={itens}
              columns={[
                {
                  field: 'nf',
                  headerName: 'Notas Fiscais',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  flex: 0,
                  getActions: ({ row }) => [
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() =>
                        setItens((prev) =>
                          prev?.filter((f) => f?.nf !== row?.nf)
                        )
                      }
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              getRowId={(row) => row?.nf}
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={onSubmit}
              loading={postLoading}
            >
              Liberar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Liberacao;
