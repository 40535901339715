import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { paginateArray, summarizer } from 'utils/functions';
import { toastWarning } from 'utils/toast';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const PAGE_COUNT = 10;

const Volume = () => {
  let timer;
  const waitTime = 1000;
  const navigate = useNavigate();
  const produtoRef = useRef(null);
  const defaultValues = {
    produto_id: '',
    quantidade: 1,
    numero: '',
    identificacao: '',
  };
  const [page, setPage] = useState(1);
  const [itens, setItens] = useState([]);
  const [selected, setSelected] = useState(null);
  const { openModal, closeModal } = useModal();
  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const { getItensLote, getLoading, postVolume, postLoading } =
    useContext(ExpedicoesContext);

  const renderItens = (data) => {
    setItens(data);
    setTimeout(() => produtoRef?.current?.focus(), 500);
  };

  useEffect(() => {
    getItensLote({ cb: renderItens });
  }, []);

  const Modal = () => {
    return (
      <>
        <DialogTitle>Confirmar Volume</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12} sm={6}>
              <Input name="numero" control={control} label="Número" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="identificacao"
                control={control}
                label="Identificação"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Confirmar
          </Button>
          <Button variant="contained" color="secondary" onClick={closeModal}>
            Cancelar
          </Button>
        </DialogActions>
      </>
    );
  };

  const onRead = (data) => {
    const prods = itens?.filter((f) => f?.produto_id == data?.produto_id);
    const total_prod = summarizer(prods, 'quantidade');
    const total_prod_vol = summarizer(prods, 'quantidade_volumada');
    let qt = Math.abs(Number(data?.quantidade));

    if (Number(data?.quantidade) > 0) {
      if (total_prod - total_prod_vol < qt) {
        return toastWarning('Quantidade Inválida');
      }
      setItens((prev) =>
        prev?.map((p, i) => {
          if (p?.produto_id == data?.produto_id) {
            const dif = p?.quantidade - p?.quantidade_volumada;
            const quantidade_adicionada = qt > dif ? dif : qt;
            if (qt > 0) {
              qt = qt - quantidade_adicionada;
              setPage(Math.ceil((i + 1) / PAGE_COUNT));
              setSelected(p?.id);
              const quantidade_volumada =
                p?.quantidade_volumada + quantidade_adicionada;
              return { ...p, quantidade_volumada };
            }
            return p;
          }
          return p;
        })
      );
    }
    if (Number(data?.quantidade) < 0) {
      if (total_prod_vol < qt) {
        return toastWarning('Quantidade Inválida');
      }
      setItens((prev) =>
        prev
          ?.reverse()
          ?.map((p, i) => {
            if (p?.produto_id == data?.produto_id) {
              const quantidade_removida =
                qt > p?.quantidade_volumada ? p?.quantidade_volumada : qt;
              if (qt > 0) {
                qt = qt - quantidade_removida;
                setPage(Math.ceil((i + 1) / PAGE_COUNT));
                setSelected(p?.id);
                const quantidade_volumada =
                  p?.quantidade_volumada - quantidade_removida;
                return { ...p, quantidade_volumada };
              }
              return p;
            }
            return p;
          })
          ?.reverse()
      );
    }
    reset();
  };

  const onSubmit = (values) => {
    const data = {
      ...values,
      itens: itens
        ?.filter((f) => Boolean(f?.quantidade_volumada))
        ?.map((m) => ({
          id: m?.id,
          quantidade: m?.quantidade_volumada,
        })),
    };
    postVolume({ data, cb: () => navigate(-1) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Adicionar Volume" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Input
              name="quantidade"
              control={control}
              label="Quantidade"
              type="number"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    produtoRef?.current?.focus();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Input
              inputRef={produtoRef}
              name="produto_id"
              control={control}
              label="Produto"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    handleSubmit(onRead)();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={paginateArray(itens, page, PAGE_COUNT)}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  width: 150,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade Total',
                  width: 200,
                  type: 'number',
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade || 0} ${row?.unidade_medida || ''}`,
                },
                {
                  field: 'quantidade_volumada',
                  headerName: 'Quantidade no Volume',
                  width: 200,
                  type: 'number',
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade_volumada || 0} ${
                      row?.unidade_medida || ''
                    }`,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              selectionModel={[selected]}
              // loading={postLoading}
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Pagination
              page={page}
              count={Math.ceil(itens?.length / PAGE_COUNT)}
              shape="rounded"
              color="primary"
              onChange={(_, page) => setPage(page)}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={() => openModal(<Modal />)}
              loading={postLoading}
            >
              SALVAR
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Volume;
