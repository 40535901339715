import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { toastWarning } from 'utils/toast';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import FilterVol from './filterVol';
import mock from './mock';

const Lotes = ({ rotina }) => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { expedicoes, getExpedicoes, getLoading } =
    useContext(ExpedicoesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: expedicoes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getExpedicoes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: expedicoes?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: expedicoes?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={expedicoes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Separação',
      icon: 'call_split',
      action: () => navigate(`/app/Expedicoes/Separacao`),
    },
  ];

  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
    {
      name: 'Lote de Expedição',
      icon: 'print',
      menu: true,
      action: ({ row }) =>
        getURLRelatorio({
          data: { codigo: 'SUP000001', documento_id: row?.id },
        }),
    },
    {
      name: 'Gerar Requisição de Compra',
      icon: 'post_add',
      menu: true,
      show: ({ row }) => Boolean(row?.status === 'PENDENTE'),
      action: ({ row }) => navigate(`/app/Requisicoes/Gerar/${row?.id}`),
    },
    {
      name: 'Gerar Ordem de Fabricação',
      icon: 'post_add',
      menu: true,
      show: ({ row }) => Boolean(row?.status === 'PENDENTE'),
      action: ({ row }) =>
        window.open(
          `https://producao.eprom2.com.br/app/Ordens/Gerar/${row?.id}`
        ),
    },
    {
      name: 'Liberar',
      icon: 'local_shipping',
      menu: true,
      action: ({ row }) => navigate(`/app/Expedicoes/Liberacao/${row?.id}`),
    },
  ];

  return (
    <Grid
      grid={expedicoes}
      mock={mock}
      options={options}
      rowOptions={rowOptions}
      control={control}
      checkboxSelection
      loading={getLoading}
      loadGrid={loadGrid}
      getRowHeight={() => 'auto'}
      activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
      FilterComponent={
        <Filter grid={expedicoes} control={control} drops={drops} />
      }
    />
  );
};

const Volumes = ({ rotina }) => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { volumes, getVolumes, getLoading, postCarregamento, postLoading } =
    useContext(ExpedicoesContext);
  const { control, getValues, reset } = useForm({
    defaultValues: volumes?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getVolumes({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Volume',
      icon: 'add',
      action: () => navigate('/app/Expedicoes/Volume'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: volumes?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: volumes?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={volumes?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Carregar Volumes',
      icon: 'local_shipping',
      action: (selected) => {
        if (!selected?.length) {
          return toastWarning('Nenhum volume selecionado');
        }
        const data = selected?.map((s) => s?.id);
        postCarregamento({ data, cb: loadGrid });
      },
    },
  ];

  const rowOptions = [];

  return (
    <Grid
      grid={volumes}
      mock={mock}
      options={options}
      rowOptions={rowOptions}
      control={control}
      checkboxSelection
      loading={getLoading || postLoading}
      loadGrid={loadGrid}
      getRowHeight={() => 'auto'}
      activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
      FilterComponent={
        <FilterVol grid={volumes} control={control} drops={drops} />
      }
    />
  );
};

const Expedicoes = () => {
  const [tab, setTab] = useState(0);
  const rotina = Boolean(tab) ? 'Volumes' : 'Expedicoes';
  const titulo = Boolean(tab) ? 'Volumes' : 'Expedições';
  const { expedicoes } = useContext(ExpedicoesContext);

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={expedicoes?.colunas} />
      <Card style={{ mb: 2 }}>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={(_, tab) => setTab(tab)}
        >
          <Tab value={0} label="Lotes" />
          <Tab value={1} label="Volumes" />
        </Tabs>
      </Card>
      <Card>
        {tab === 0 && <Lotes rotina={rotina} />}
        {tab === 1 && <Volumes rotina={rotina} />}
      </Card>
    </Container>
  );
};

export default Expedicoes;
