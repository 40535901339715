import { createContext, useState } from 'react';
import api from 'services/api';

const filter = {
  like: '',
  id: '',
  status: ['PENDENTE'],
  situacao: [],
  dtemissao: {
    i: null,
    f: null,
  },
  pedidos: '',
  transporte: '',
};

const filterVol = {
  like: '',
  id: '',
  numero: '',
  identificacao: '',
};

export const ExpedicoesContext = createContext();

export const ExpedicoesProvider = ({ children }) => {
  const [expedicoes, setExpedicoes] = useState({
    data: [],
    colunas: [],
    order: { field: 'dtemissao', sort: 'desc' },
    filter,
    page: 0,
    size: 10,
  });
  const [volumes, setVolumes] = useState({
    data: [],
    colunas: [],
    filter: filterVol,
    page: 0,
    size: 10,
  });
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getExpedicoes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: expedicoes?.filter,
        page: expedicoes?.page,
        size: expedicoes?.size,
        order: expedicoes?.order,
        ...payload,
      });
      setExpedicoes((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const deleteExpedicao = async ({ data, cb }) => {
    try {
      setDeleteLoading(true);
      await api.post('/Suprimentos/Expedicoes/Delete', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const getItensPedido = async ({ cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Itens');
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getItensLote = async ({ cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/ItensLote');
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postSeparacao = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Suprimentos/Expedicoes/Separacao', data);
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postEmbalagem = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Suprimentos/Expedicoes/Embalagem', data);
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const getVolumes = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: volumes?.filter,
        page: volumes?.page,
        size: volumes?.size,
        order: volumes?.order,
        ...payload,
      });
      setVolumes((prev) => ({ ...prev, ...data, ...payload }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postVolume = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Volume', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postCarregamento = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Carregamento', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postLiberacao = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Liberacao', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ExpedicoesContext.Provider
      value={{
        expedicoes,
        getLoading,
        postLoading,
        deleteLoading,
        volumes,
        getVolumes,
        postVolume,
        getItensLote,
        getExpedicoes,
        postSeparacao,
        postEmbalagem,
        postLiberacao,
        getItensPedido,
        deleteExpedicao,
        postCarregamento,
      }}
    >
      {children}
    </ExpedicoesContext.Provider>
  );
};
