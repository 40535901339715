import { Check, Close } from '@mui/icons-material';
import { Box, Chip, Divider, Typography } from '@mui/material';
import moment from 'moment';

const mock = [
  {
    field: 'status',
    renderCell: ({ value }) => renderStatus(value),
  },
  {
    field: 'dtemissao',
    valueGetter: ({ value }) => value && moment(value).format('DD/MM/YYYY'),
  },
  {
    field: 'pedidos',
    renderCell: ({ row }) => (
      <Box flex={1} my={1}>
        {row?.pedidos?.map((p, i) => (
          <Box display="flex" flexDirection="column" flex={1}>
            {Boolean(i) && <Divider sx={{ my: 0.5 }} />}
            <Typography variant="caption" fontWeight="bold">
              Pedido Nº {p?.documento}
            </Typography>
            <Typography variant="caption">
              Para {p?.cliente}
              {Boolean(p?.dtemissao) &&
                moment(p?.dtemissao).isValid() &&
                `, emitido em ${moment(p?.dtemissao).format(
                  'DD/MM/YYYY [às] HH:mm'
                )}`}
            </Typography>
          </Box>
        ))}
      </Box>
    ),
  },
  {
    field: 'transporte',
    renderCell: ({ row }) => {
      const lines = [
        { label: 'Transportador', value: row?.transporte?.transportador },
        { label: 'Motorista', value: row?.transporte?.motorista },
        { label: 'Frota', value: row?.transporte?.frota },
      ];

      return (
        <Box flex={1} my={1}>
          {lines?.map(
            ({ label, value }, i) =>
              Boolean(value) && (
                <>
                  {Boolean(i) && <Divider sx={{ my: 0.5 }} />}
                  <Box display="flex" flex={1}>
                    <Typography variant="caption" fontWeight="bold" flex={1}>
                      {label}:
                    </Typography>
                    <Typography variant="caption" flex={2}>
                      {value || '-'}
                    </Typography>
                  </Box>
                </>
              )
          )}
        </Box>
      );
    },
  },
  {
    field: 'itens',
    renderCell: ({ row }) => (
      <Box flex={1} my={1}>
        {row?.itens?.map((it, i) => (
          <>
            {Boolean(i) && <Divider sx={{ my: 0.5 }} />}
            <Box display="flex" flex={1}>
              <Typography variant="caption" flex={2}>
                {it?.produto}
              </Typography>
              <Box flex={1} ml={2}>
                <Typography variant="caption" fontWeight="bold">
                  Qtd.
                </Typography>
                <Typography variant="caption">
                  {it?.quantidade} {it?.um}
                </Typography>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    ),
  },
  {
    field: 'carregado',
    renderCell: ({ row }) => (
      <Box flex={1}>
        <Chip
          size="small"
          variant="outlined"
          label={row?.carregado === 'SIM' ? 'Carregado' : 'Não Carregado'}
          icon={row?.carregado === 'SIM' ? <Check /> : <Close />}
          color={row?.carregado === 'SIM' ? 'success' : 'error'}
        />
      </Box>
    ),
  },
];

const renderStatus = (value) => {
  const color =
    value === 'CONCLUIDO'
      ? '#7FFF7F'
      : value === 'PENDENTE'
      ? '#FFFF7F'
      : value === 'CANCELADO'
      ? '#FF7F7F'
      : 'transparent';

  const style = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color,
    borderRadius: '50px',
  };
  return (
    <div style={style}>
      <span>{value}</span>
    </div>
  );
};

export default mock;
