import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { paginateArray } from 'utils/functions';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Card from 'components/Card';

const PAGE_COUNT = 10;

const Separacao = () => {
  let timer;
  const waitTime = 1000;
  const enderecoRef = useRef(null);
  const produtoRef = useRef(null);
  const defaultValues = { quantidade: 1, ceendereco_id: '', produto_id: '' };
  const [page, setPage] = useState(1);
  const [Itens, setItens] = useState([]);
  const [selected, setSelected] = useState(null);
  const { getItensLote, getLoading, postSeparacao, postLoading } =
    useContext(ExpedicoesContext);
  const { control, reset, handleSubmit } = useForm({ defaultValues });

  const renderItens = (data) => {
    setItens(data);
    setTimeout(() => enderecoRef?.current?.focus(), 500);
  };

  const loadItens = () => {
    getItensLote({ cb: renderItens });
    setPage(1);
  };

  useEffect(() => {
    loadItens();
  }, []);

  const onSubmit = (data) => {
    const cb = (res) => {
      setItens((prev) =>
        prev?.map((p, i) => {
          if (p?.id == res?.id) {
            setPage(Math.ceil((i + 1) / PAGE_COUNT));
            setSelected(p?.id);
            const quantidade_separada =
              p?.quantidade_separada + Number(data?.quantidade);
            return { ...p, quantidade_separada };
          }
          return p;
        })
      );
    };
    postSeparacao({ data, cb });
    reset();
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Separação" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Input
              name="quantidade"
              control={control}
              label="Quantidade"
              type="number"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    enderecoRef?.current?.focus();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              inputRef={enderecoRef}
              name="ceendereco_id"
              control={control}
              label="Endereço de Estoque"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    produtoRef?.current?.focus();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              inputRef={produtoRef}
              name="produto_id"
              control={control}
              label="Produto"
              onKeyUp={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  const value = e?.target?.value;
                  if (Boolean(value)) {
                    handleSubmit(onSubmit)();
                    enderecoRef?.current?.focus();
                  }
                }, waitTime);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={paginateArray(Itens, page, PAGE_COUNT)}
              columns={[
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  width: 150,
                  sortable: false,
                },
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade Total',
                  width: 200,
                  type: 'number',
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade || 0} ${row?.unidade_medida || ''}`,
                },
                {
                  field: 'quantidade_separada',
                  headerName: 'Quantidade Separada',
                  width: 200,
                  type: 'number',
                  sortable: false,
                  valueGetter: ({ row }) =>
                    `${row?.quantidade_separada || 0} ${
                      row?.unidade_medida || ''
                    }`,
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              selectionModel={[selected]}
              loading={postLoading}
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Pagination
              page={page}
              count={Math.ceil(Itens?.length / PAGE_COUNT)}
              shape="rounded"
              color="primary"
              onChange={(_, page) => setPage(page)}
            />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Separacao;
